import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import api from '../../../../services/api';
import { fileUploadImg, fileUploadInfluencerImg } from '../../../../services/s3';
import InfluencerManagerContext from '../../InfluencerManagerContext';

import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const Image = ({ propData }) => {
  const { viewLoading, viewError, viewContent } = useContext(
    InfluencerManagerContext
  );

  const [objDefault, setDefault] = useState({
    infThumbnailAvatar: 'https://cdn.influencer.alivethenetwork.tv/influencerAvatar.png',
    infThumbnailBanner: 'https://cdn.influencer.alivethenetwork.tv/influencerBanner.png',
  });
  const [objData, setData] = useState(propData);
  const [objTemp, setTemp] = useState({
    infThumbnailAvatar: objData.infThumbnailAvatar,
    infThumbnailBanner: objData.infThumbnailBanner,
  });
  // console.log(objData);

  const optStatus = [
    { id: 'Active', title: 'Active' },
    // { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    // { id: 'Blocked', title: 'Blocked' },
  ];

  const handleCheckFiles = data => {
    console.log('#btn => Images -> Chek File');
    console.log(data);
  };

  const handleChangeFileAvatar = async img => {
    console.log('#infThumbnailAvatar => Upload -> Start');

    // console.log(img);
    try {
      const fileUpload = await fileUploadImg(
        'INFLUENCER',
        objData.infId,
        'avatar',
        img[0]
      );
      console.log(fileUpload);

      // Validate Resolutions
      let comImage = document.createElement('img');
      comImage.src = fileUpload.link;
      comImage.onload = function() {
        // console.log(comImage.naturalWidth, comImage.naturalHeight);

        if (comImage.naturalWidth !== 400 || comImage.naturalHeight !== 400) {
          N.error('Image', 'Incorrect resolution');
          return false;
        }

        setTemp(prevState => {
          return {
            ...prevState,
            infThumbnailAvatar: fileUpload.link,
          };
        });
        console.log('#infThumbnailAvatar => Upload -> End');
      }
    } catch (e) {
      console.error(e);
      console.log('#infThumbnailAvatar => Upload -> Error');
    }
  };

  const handleChangeFileBanner = async img => {
    console.log('#infThumbnailBanner => Upload -> Start');

    // console.log(img);
    try {
      const fileUpload = await fileUploadImg(
        'INFLUENCER',
        objData.infId,
        'banner',
        img[0]
      );
      console.log(fileUpload);

      // Validate Resolutions
      let comImage = document.createElement('img');
      comImage.src = fileUpload.link;
      comImage.onload = function() {
        // console.log(comImage.naturalWidth, comImage.naturalHeight);

        if (comImage.naturalWidth !== 1920 || comImage.naturalHeight !== 480) {
          N.error('Image', 'Incorrect resolution');
          return false;
        }

        setTemp(prevState => {
          return {
            ...prevState,
            infThumbnailBanner: fileUpload.link,
          };
        });
        console.log('#infThumbnailBanner => Upload -> End');
      }
    } catch (e) {
      console.error(e);
      console.log('#infThumbnailBanner => Upload -> Error');
    }
  };

  const handleSubmit = async data => {
    try {
      // viewLoading();
      const data = {
        infThumbnailAvatar: objTemp.infThumbnailAvatar,
        infThumbnailBanner: objTemp.infThumbnailBanner,
      };
      console.log(`Data PUT`, data);
      const resPutData = await api(
        'PUT',
        `/influencer/${objData.infId}/image`,
        data
      );
      console.log(resPutData);

      N.success('Successfully updated');

      setTimeout(() => {
        // history.push('/');
        // viewContent();
      }, 1000);


    } catch (error) {
      console.error(error);
      N.error('Error updating');
      // viewContent();
    }
  };

  return (
    <>
      <S.Form onSubmit={handleCheckFiles}>
        <S.DivInput propW="20%">
          <small>
            Avatar <small>400px x 400px</small>
          </small>
          <S.LabelInputImage htmlFor="file-avatar">
            <img
              src={objTemp.infThumbnailAvatar}
              alt="Thumbnail Avatar"
              onError={() =>
                setTemp(prevState => {
                  return {
                    ...prevState,
                    tmpThumbnailSquare: objDefault.infThumbnailAvatar,
                  };
                })
              }
            />
            <input
              id="file-avatar"
              type="file"
              accept="image/*"
              onChange={e => handleChangeFileAvatar(e.target.files)}
            />
          </S.LabelInputImage>
        </S.DivInput>
        <S.DivInput propW="80%">
          <small>
            Banner <small>1920px x 480px</small>
          </small>
          <S.LabelInputImage htmlFor="file-banner">
            <img
              src={objTemp.infThumbnailBanner}
              alt="Thumbnail Banner"
              onError={() =>
                setTemp(prevState => {
                  return {
                    ...prevState,
                    tmpThumbnailSquare: objDefault.infThumbnailBanner,
                  };
                })
              }
            />
            <input
              id="file-banner"
              type="file"
              accept="image/*"
              onChange={e => handleChangeFileBanner(e.target.files)}
            />
          </S.LabelInputImage>
        </S.DivInput>
      </S.Form>
      <S.Form onSubmit={handleSubmit}>
        <S.DivButton>
          <ButtonLoader propTitle="SAVE" propSpinner={false} />
        </S.DivButton>
      </S.Form>
      <NotificationContainer />
    </>
  );
};

Image.defaultProps = {
  propData: {},
};

Image.propTypes = {
  propData: PropTypes.object,
};

export default Image;
