import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios'
import { format } from 'date-fns';
import ReactPlayer from 'react-player';
import Dropzone from 'react-dropzone';
import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import config from '../../../../config/aws';
import api from '../../../../services/api';
import { fileUploadImg, fileUploadMedia } from '../../../../services/s3';
import MediaManagerContext from '../../MediaManagerContext';

import imgLoading from '../../../../assets/images/loading/icoLoading-2.svg';
import imgSuccess from '../../../../assets/images/loading/icoSuccess-1.svg';
import imgSearch from '../../../../assets/images/loading/icoSearch-1.svg';

import CustomLinearProgress from '../../../../components/CustomLinearProgress';
import Loader from '../../../../components/Loader';
import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';
import ButtonNew from '../../../../components/ButtonNew';
import ButtonAdd from '../../../../components/ButtonAdd';
import ButtonBack from '../../../../components/ButtonBack';
import BadgeArtist from '../../../../components/BadgeArtist';
import Table from '../../../../components/Table';
import { led } from '../../../../utils/led';
import { formatNumberTwoDigit } from '../../../../utils/format';
import 'react-notifications/lib/notifications.css';

import * as S from './styles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  divDropzone: {
    borderRadius: "4px",
    padding: "30px",
    textAlign: "center",
    border: "1px dashed #e4c1cb",
    cursor: "pointer",
    color: "#e71a69",
    "& > p": {
      margin: 0,
      fontSize: "15px",
    },
    "&:hover": {
      border: "2px dashed #e71a69",
    },
    "&:disabled": {
      border: "2px dashed #b7b7b7",
      color: "#b7b7b7",
    },
  },
  divDropzoneDisabled: {
    borderRadius: "4px",
    padding: "30px",
    textAlign: "center",
    cursor: "progress",
    border: "2px dashed #b7b7b7",
    color: "#b7b7b7",
    "& > p": {
      margin: 0,
      fontSize: "18px",
    },
  },
  modalRoot: {
    overflow: "auto",
    display: "block"
  },
  modal: {
    maxWidth: "250px",
    margin: "auto",
    borderRadius: "6px",
    overflow: "visible",
    maxHeight: "unset",
    width: "100%",
    marginTop: "130px !important",
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px"
  },
  modalTitle: {
    margin: "0",
    lineHeight: "1.5",
    textAlign: "center",
    color: "#03163d",
  },
  modalBody: {
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "16px",
    paddingLeft: "24px",
    position: "relative",
    overflow: "visible",
    textAlign: "center",
  },
  imgProgress: {
    width: "75px",
  },
  imgProgressS: {
    width: "75px",
    padding: "15px",
  },
}));


const Link = ({ propData }) => {
  const history = useHistory();
  const classes = useStyles();

  const { viewLoading, viewError, viewContent } = useContext(
    MediaManagerContext
  );
  const [typeMedia, setTypeMedia] = useState('[undefined]');
  const [lstData, setDataTable] = useState([]);
  const [objDataMain, setDataMain] = useState(propData);
  const [isStatus, setStatus] = useState(false);
  const [isManagerData, setManagerData] = useState(false);
  const [isManagerDataNew, setManagerDataNew] = useState(false);
  const [lstSongArtist, setSongArtist] = useState([]);
  const [objSong, setSong] = useState({});
  const [songLinkPlayer, setSongLinkPlayer] = useState(null);

  const [objLink, setLink] = useState({});
  const [objLinkBkp, setLinkBkp] = useState({});
  const [hlsLinkPlayer, setHlsLinkPlayer] = useState(null);

  const [isDropzone, setIsDropzone] = useState(true);
  const [lstArtist, setArtist] = useState([]);
  const [isThumbnailGenerateMain, setThumbnailGenerateMain] = useState(true);

  const [upProgress, setUpProgress] = useState(0);
  const [modUpload, setModUpload] = useState(false);
  const [modArtist, setModArtist] = useState(false);

  const [objDefault, setDefault] = useState({
    medLinkThumbnailFrame: "https://cdn.media.alivethenetwork.tv/mediaThumbnailLand.png",
  })
  const [objTemp, setTemp] = useState({})
  const [isSearch, setSearch] = useState(false)
  const player = React.createRef();

  const optStatus = [
    { id: 'Active', title: 'Active' },
    { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    { id: 'Blocked', title: 'Blocked' },
  ];
  const optStatusSimple = [
    { id: 'Active', title: 'Active' },
    { id: 'Disabled', title: 'Disabled' },
  ];
  const optType = [
    { id: 'Trailer', title: 'Trailer' },
    { id: 'Film', title: 'Film' },
    // { id: 'Disabled', title: 'Disabled' },
    // { id: 'Blocked', title: 'Blocked' },
  ];
  const optYearReleased = [
    { id: '2022', title: '2022' },
    { id: '2021', title: '2021' },
    { id: '2020', title: '2020' },
    { id: '2019', title: '2019' },
    { id: '2018', title: '2018' },
    { id: '2017', title: '2017' },
    { id: '2016', title: '2016' },
    { id: '2015', title: '2015' },
    { id: '2014', title: '2014' },
    { id: '2013', title: '2013' },
    { id: '2012', title: '2012' },
    { id: '2011', title: '2011' },
    { id: '2010', title: '2010' },
  ];
  const optExplicitLyrics = [
    { id: 'Not', title: 'Not' },
    { id: 'Yes', title: 'Yes' },
  ];
  const optSale = [
    { id: 'Not', title: 'Not' },
    { id: 'Yes', title: 'Yes' },
  ];
  const optSalePrice = [
    { id: '0.00', title: '0.00' },
    { id: '0.99', title: '0.99' },
    { id: '1.99', title: '1.99' },
    { id: '6.99', title: '6.99' },
    { id: '12.99', title: '12.99' },
    { id: '15.99', title: '15.99' },
    { id: '19.99', title: '19.99' },
  ];

  const objColumns = [
    {
      title: '',
      field: '_medLinkStatusLed',
      formatter: 'html',
      width: 15,
      align: 'center',
    },
    {
      title: 'Control',
      field: 'medLinkControl',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Type',
      field: '_medLinkType',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Season',
      field: 'medLinkSeason',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Episode',
      field: 'medLinkEpisode',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Title',
      field: 'medTitle',
      headerFilter: 'input'
    },
    {
      title: 'Created',
      field: '_medLinkDateCreated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Updated',
      field: '_medLinkDateUpdated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
  ];
  const objColumnsArtist = [
    {
      title: '',
      field: '_artStatus',
      formatter: 'html',
      width: 15,
      align: 'center',
      // headerFilter: 'select',
      // headerFilterParams: {
      //   values: {
      //     0: 'Inactive',
      //     1: 'Active',
      //   },
      // },
    },
    { title: 'Name', field: 'artName', headerFilter: 'input' },
    {
      title: 'Created',
      field: '_artDateCreated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Updated',
      field: '_artDateUpdated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
  ];

  const auxFormatData = objData => {
    objData.id = objData.medKey;
    objData._medKey = objData.medKey;
    objData._medLinkStatusLed = led(objData.medLinkStatus);
    switch (objData.medLinkStatus) {
      case 0:
        objData._medLinkStatus = 'Active';
        break;
      case 1:
        objData._medLinkStatus = 'Processing';
        break;
      case 2:
        objData._medLinkStatus = 'Disabled';
        break;
      case 3:
        objData._medLinkStatus = 'Blocked';
        break;
      default:
        objData._medLinkStatus = 'Disabled';
        break;
    }
    objData._medLinkType = objData.medKey.substr(5);
    objData._medContent = objData.medKey.match(/trailer/) ? 'Trailer' : 'Film';
    objData._medLinkControlNull = 0;
    objData._medLinkSeasonNull = 0;
    objData._medLinkEpisodeNull = 0;
    objData._medLinkDateCreated = format(
      new Date(objData.medLinkDateCreated),
      'MM/dd/yyyy'
    );
    objData._medLinkDateUpdated = format(
      new Date(objData.medLinkDateUpdated),
      'MM/dd/yyyy'
    );
    console.log(objData);
    return objData;
  };

  useEffect(() => {
    try {
      console.log(objDataMain);

      handleListLinks();

      // let lstTrailers = objDataMain !== null ? objDataMain.LINKS_TRAILER.map(auxFormatData) : [];
      // let lstFilms = objDataMain !== null ? objDataMain.LINKS.map(auxFormatData) : [];
      // const tmpDataLinks = lstTrailers.concat(lstFilms);
      // console.log(tmpDataLinks);

      // setDataTable(tmpDataLinks);
      // setStatus(true);
    } catch (error) {
      console.error(error);
      viewError();
    }
  }, []);

  useEffect(() => {
    setLinkBkp(objLink);
    if (typeMedia === 'Trailer') {
        setLink(prevState => {
          return {
            ...prevState,
            medLinkControl: 0,
            medLinkSeason: 0,
            medLinkEpisode: 0,
          };
        })
    } else { // Film
      setLink(prevState => {
        return {
          ...prevState,
          medLinkControl: objLink.medLinkControl,
          medLinkSeason: objLink.medLinkSeason,
          medLinkEpisode: objLink.medLinkEpisode,
        };
      })
    }
  }, [typeMedia])

  const fncGetLink = async medKey => {
    try {
      console.log('fncGetLink')
      // const apiGetData = api('GET', `/media/${objDataMain.id}/link/${medKey}`, null);
      // const resGetData = await apiGetData;
      // const {
      //   data: { resData },
      // } = resGetData;
      // console.log(resData);
      // return resData;

      // kes-dev-tmp
      if (medKey === 'link-trailer') {
        return objDataMain.LINKS_TRAILER[0]
      } else {
        let objLinkTemp = null;
        for (let i = 0; i < objDataMain.LINKS.length; i++) {
          if (objDataMain.LINKS[i].medKey === medKey) {
            objLinkTemp = objDataMain.LINKS[i]
          }
        }
        return objLinkTemp
      }
    } catch (error) {
      N.error('Link', 'Error load');
      // setTimeout(() => {
      //   window.location.reload();
      // }, 500);
      return null
    }
  };

  const handleViewLink = async medKey => {
    console.log('handleViewLink');
    console.log(medKey);
    const objResult = await fncGetLink(medKey);
    console.log(objResult);
    setLink(objResult);
    setTemp({
      medLinkThumbnailFrame: objResult.medLinkThumbnailFrame,
    });
    setTypeMedia(objResult._medContent);
    setHlsLinkPlayer(objResult.medLinkStreamHls);
    setManagerDataNew(false);
    setManagerData(true);
  };

  const handleNewLink = () => {
    console.log('handleNewLink');
    console.log('new');
    setLink({
      medId: objDataMain.medId,
      _medLinkStatus: null,
      medTitle: '',
      medLinkControl: 1,
      medLinkSeason: 1,
      medLinkEpisode: 1,
      medDescription: '',
      _medKey : '',
      _medLinkType : '',
      _medContent : 'Film',
      _medLinkControlNull : 0,
      _medLinkSeasonNull : 0,
      _medLinkEpisodeNull : 0,
      _medLinkDateCreated : '',
      _medLinkDateUpdated : '',
    });
    setTypeMedia('Film');
    setSongLinkPlayer(null);
    setManagerDataNew(true);
    setManagerData(true);
  };

  const handleListLinks = () => {
    console.log('handleListLinks');

    async function loadData() {
      const apiGetData = api('GET', `/media/${objDataMain.medId}`, null);

      const resGetData = await apiGetData;
      const {
        data: { resData },
      } = resGetData;
      console.log(resData);

      let lstTrailers = resData !== null ? resData.LINKS_TRAILER.map(auxFormatData) : [];
      let lstFilms = resData !== null ? resData.LINKS.map(auxFormatData) : [];
      const tmpDataLinks = lstTrailers.concat(lstFilms);

      const tmpData = resData !== null ? auxFormatDataMedia(resData) : {};

      setDataMain(tmpData);
      setDataTable(tmpDataLinks);
      setStatus(true);
      setManagerData(false);

      function auxFormatDataMedia(data) {
        data.id = data.medId;
        switch (data.medStatus) {
          case 0:
            data._medStatus = 'Active';
            break;
          case 1:
            data._medStatus = 'Processing';
            break;
          case 2:
            data._medStatus = 'Disabled';
            break;
          case 3:
            data._medStatus = 'Blocked';
            break;
          default:
            data._medStatus = 'Disabled';
            break;
        }
        switch (data.medFeaturedMain) {
          case 0:
            data._medFeaturedMain = 'Active';
            break;
          case 1:
            data._medFeaturedMain = 'Disabled';
            break;
          default:
            data._medFeaturedMain = 'Disabled';
            break;
        }
        switch (data.medFeatured) {
          case 0:
            data._medFeatured = 'Active';
            break;
          case 1:
            data._medFeatured = 'Disabled';
            break;
          default:
            data._medFeatured = 'Disabled';
            break;
        }
        data._medDateCreated = format(new Date(data.medDateCreated), 'MM/dd/yyyy');
        data._medDateUpdated = format(new Date(data.medDateUpdated), 'MM/dd/yyyy');
        return data;
      }
    }

    try {
      loadData();
    } catch (error) {
      console.error(error);
      viewError();
    }
  };

  const handleSubmit = async data => {
    try {
      if (handleValidate()) {
        if (isManagerDataNew) {
          // viewLoading();
          setStatus(false);
          data = {
            ...data,
          };
          console.log(`Data POST`, data);
          const resPostData = await api('POST', `/media/${objLink.medId}/link/info`, data);
          console.log(resPostData);

          N.success('Successfully created');

          setTimeout(() => {
            // history.push(`/album/${resPostData.data.resData.albId}`); aaaaaaaaaaaaaaaaaaaa
            // window.location.reload();

            // setStatus(true);
            handleListLinks();
          }, 1500);
        } else {
          setStatus(false);
          console.log(`Data PUT`, data);

          const resPutData = await api('PUT', `/media/${objLink.medId}/link/info`, data);
          console.log(resPutData);

          N.success('Successfully updated');

          setTimeout(() => {
            // window.location.reload(); aaaaaaaaaaaaaaaaaa
            data.medKey = data.medLinkControl === 0 ? `link-trailer` : `link-s${formatNumberTwoDigit(data.medLinkSeason)}e${formatNumberTwoDigit(data.medLinkEpisode)}`;
            setLink({ ...objLink, ...data });
            setStatus(true);
          }, 1500);
        }
      }

      function handleValidate() {
        let varControl = true;

        console.log(data)
        if (data._medLinkStatus !== 'Active' && data._medLinkStatus !== 'Processing' && data._medLinkStatus !== 'Disabled' && data._medLinkStatus !== 'Blocked') {
          varControl = false;
          N.error('Status', 'Check parameters');
        } else {
          if (isManagerDataNew || (objLink.medLinkStatus !== 1 && objLink.medLinkStatus !== 3)) {
            if (data._medLinkStatus !== 'Active' && data._medLinkStatus !== 'Disabled') {
              varControl = false;
              N.error('Status', 'Check parameters');
            }
          }
          switch (data._medLinkStatus) {
            case 'Active':
              data.medLinkStatus = 0;
              break;
            case 'Processing':
              data.medLinkStatus = 1;
              break;
            case 'Disabled':
              data.medLinkStatus = 2;
              break;
            case 'Blocked':
              data.medLinkStatus = 3;
              break;
            default:
              varControl = false;
              N.error('Error Status', 'Check parameters');
              break;
          }
        }

        if (data.medLinkControl == '') {
          varControl = false;
          N.error('Control', 'Check parameters');
        } else {
          const tmpMedLinkControl = parseInt(data.medLinkControl);
          if (tmpMedLinkControl >= 0 && tmpMedLinkControl <= 99) {
            data.medLinkControl = tmpMedLinkControl;
          } else {
            varControl = false;
            N.error('Control [invalid]', 'Check parameters');
          }
        }

        if (data.medLinkSeason == '') {
          varControl = false;
          N.error('Season', 'Check parameters');
        } else {
          const tmpMedLinkSeason = parseInt(data.medLinkSeason);
          if (tmpMedLinkSeason >= 0 && tmpMedLinkSeason <= 99) {
            data.medLinkSeason = tmpMedLinkSeason;
          } else {
            varControl = false;
            N.error('Season [invalid]', 'Check parameters');
          }
        }

        if (data.medLinkEpisode == '') {
          varControl = false;
          N.error('Episode', 'Check parameters');
        } else {
          const tmpMedLinkEpisode = parseInt(data.medLinkEpisode);
          if (tmpMedLinkEpisode >= 0 && tmpMedLinkEpisode <= 99) {
            data.medLinkEpisode = tmpMedLinkEpisode;
          } else {
            varControl = false;
            N.error('Episode [invalid]', 'Check parameters');
          }
        }

        if (typeMedia === 'Trailer') {
          data.medLinkControl = 0;
          data.medLinkSeason = 0;
          data.medLinkEpisode = 0;
        } else {
          // Film
          if (data.medLinkControl <= 0) {
            varControl = false;
            N.error('Control less than 0', 'Check parameters');
          }
          if (data.medLinkSeason <= 0 ) {
            varControl = false;
            N.error('Season less than 0', 'Check parameters');
          }
          if (data.medLinkEpisode <= 0) {
            varControl = false;
            N.error('Episode less than 0', 'Check parameters');
          }
        }

        if (data.medTitle === '') {
          varControl = false;
          N.error('Title', 'Check parameters');
        }

        if (data.medDescription === '') {
          varControl = false;
          N.error('Description', 'Check parameters');
        }

        return varControl;
      }
    } catch (error) {
      console.error(error);
      console.error(error.response.data);
      if (error.response.status === 409) {
        N.error(error.response.data.resMessage, 'Error save');
        setTimeout(() => {
          setLink({ ...objLink, ...data });
          setStatus(true);
        }, 1500);
      } else {
        N.error('Error save');
      }
      // viewContent();
    }
  };

  const mediaHandleUpload = async file => {
    console.log('mediaHandleUpload');
    console.log(file[0]);

    // Referencing file
    // file.current = file[0];

    if (file[0] && file[0].size > config.S3_MEDIA_FILM.MAX_ATTACHMENT_SIZE) {
      // console.log("sim");
      N.error(
        `Please pick a file smaller than ${(config.S3_MEDIA_FILM.MAX_ATTACHMENT_SIZE /
          1000000000).toFixed(2)} GB.`,
        'Click me!'
      );
      return;
    }

    let myIntervalProcessing = setInterval(() => {
      console.log('myIntervalProcessing')
    }, 1000)
    clearInterval(myIntervalProcessing)

    let fileUpload = null;
    try {
      setUpProgress(0);
      setIsDropzone(false);
      setModUpload(true);

      let fileUpload = file[0]
        ? await fileUploadMedia(
          'film',
          objLink.medId,
          file[0],
          progress => {
            if (progress < 94) {
              setUpProgress(progress);
            }
          })
        : null;
      console.log(fileUpload);

      setSearch(true);

      if (fileUpload) {
        setUpProgress(95)

        myIntervalProcessing = setInterval(myTimerProcessing, 5000)

      }

      let varControlProcessing = false;
      async function myTimerProcessing() {
        try {
          const resMediaPut = await api(
            "PUT",
            `/media/${objLink.medId}/link/processing`,
            {
              movieKey: fileUpload.key,
              medKey: objLink.medKey,
              isThumbnailGenerateMain,
            }
          );
          console.log(resMediaPut)

          if (resMediaPut) {
            varControlProcessing = true;
            myStopFunctionProcessing()
          }

        } catch (error) {
          console.log(error)
          if (error.response.status === 500) {
            myStopFunctionProcessing()
          }
        }
      }

      function myStopFunctionProcessing() {
        if (varControlProcessing) {
          N.success("Successfully updated");
          clearInterval(myIntervalProcessing)
          setUpProgress(100)
          setTimeout(() => {
            // checkThumbnail();
            setSearch(false);
            setIsDropzone(true);
            setModUpload(false);
            handleListLinks();
          }, 1500)
        }
      }

      async function checkThumbnail () {
        console.log('checkThumbnail')

        try {
          console.log('=> mediaGetIdAll -> Start')
          const apiGetData = await api('GET', `/media/${objLink.medId}/link/${objLink.medKey}`, null);
          const objMediaResult = apiGetData.data.resData;
          console.log('=> mediaGetIdAll -> End')

          if (objMediaResult) {
            console.log(objMediaResult)
            const myInterval = setInterval(myTimer, 1000)

            function myTimer() {
              console.log('checkThumbnail => start')
              axios
                .get(objMediaResult.medLinkThumbnailFrame)
                .then(() => {
                  console.log('checkThumbnail => success')
                  myStopFunction()
                })
                .catch(() => console.log('checkThumbnail => error'))
            }

            function myStopFunction() {
              clearInterval(myInterval)
              // kes-dev-tmp
              setSearch(false);
              setIsDropzone(true);
              setModUpload(false);
              handleListLinks();
            }

          } else {
            N.error('Error undefined', 'Save error');
          }
        } catch (error) {
          console.error(error);
          N.error(`Error upload Processing`);
          setIsDropzone(true);
          setModUpload(false);
        }

      }

    } catch (e) {
      console.error(e);
      N.error(`Error upload`);
      setIsDropzone(true);
      setModUpload(false);
    }
  };

  const mediaHandleUploadError = file => {
    console.log('mediaHandleUploadError');
    console.error(file);
  };

  const handleCloseArtist = async () => {
    setModArtist(false);
  };

  const handleViewArtist = async () => {
    const auxFormatData = objData => {
      objData.id = objData.artId;
      objData._artStatus =
        objData.artStatus === 0
          ? '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #ABFF00; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;"></div>'
          : '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #FF0000; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #470101 0 -1px 9px, #ff0000 0 2px 12px;"></div>';
      objData._artDateCreated = format(
        new Date(objData.artDateCreated),
        'MM/dd/yyyy'
      );
      objData._artDateUpdated = format(
        new Date(objData.artDateUpdated),
        'MM/dd/yyyy'
      );
      return objData;
    };

    const resGetData = await api('GET', '/artist', null);
    const {
      data: { resData },
    } = resGetData;

    console.log(resData);
    const tmpData = resData !== null ? resData.map(auxFormatData) : [];

    setArtist(tmpData);
    setModArtist(true);
  };

  const handleAddArtist = async artId => {
    console.log('handleAddArtist');
    console.log(artId);

    try {
      const resGetData = await api('POST', `/song/${objSong.id}/artist`, {
        artId,
        albId: objDataMain.albId,
      });
      const {
        data: { resData },
      } = resGetData;
      console.log(resData);

      N.success('Successfully inserted');

      handleViewLink(objSong.id);
      setModArtist(false);
    } catch (error) {
      N.error('Artist', 'Error while inserting');
    }
  };

  const handleRemoveArtist = async artId => {
    console.log('handleRemoveArtist');
    console.log(artId);

    try {
      const resGetData = await api('DELETE', `/song/${objSong.id}/artist`, {
        artId,
      });
      const {
        data: { resData },
      } = resGetData;
      console.log(resData);

      N.success('Successfully removed');

      handleViewLink(objSong.id);
    } catch (error) {
      N.error('Artist', 'Error removing');
    }
  };

  const handleChangeFileFrame = async img => {
    console.log('#medLinkThumbnailFrame => Upload -> Start');

    // console.log(img);
    try {
      setStatus(false)
      const fileUpload = await fileUploadImg(
        'MEDIA',
        objLink.medId,
        'frame',
        img[0]
      );
      console.log(fileUpload);

      // Validate Resolutions
      let comImage = document.createElement('img');
      comImage.src = fileUpload.link;
      comImage.onload = function() {
        // console.log(comImage.naturalWidth, comImage.naturalHeight);

        if (comImage.naturalWidth !== 1920 || comImage.naturalHeight !== 1080) {
          N.error('Image', 'Incorrect resolution');
          setStatus(true);
          return false;
        }

        setTemp(prevState => {
          return {
            ...prevState,
            medLinkThumbnailFrame: fileUpload.link,
          };
        });

        handleSubmitFrame(fileUpload.link);

        console.log('#medLinkThumbnailFrame => Upload -> End');
      }
    } catch (e) {
      console.error(e);
      setStatus(true)
      console.log('#medLinkThumbnailFrame => Upload -> Error');
    }
  };

  const handleSubmitFrame = async link => {
    try {
      // viewLoading();
      const data = {
        medKey: objLink.medKey,
        medLinkThumbnailFrame : link,
      }
      console.log(`Data PUT`, data);
      const resPutData = await api('PUT', `/media/${objLink.medId}/link/image`, data);
      console.log(resPutData);

      N.success('Successfully updated');

      setTimeout(() => {
        setStatus(true)
      }, 1000);

    } catch (error) {
      console.error(error);
      N.error('Error updating');
      setStatus(true)
      // viewContent();
    }
  }

  function toggle(value){
    return !value;
  }

  useEffect(() => {
    console.log('isThumbnailGenerateMain', isThumbnailGenerateMain);
  }, [isThumbnailGenerateMain]);

  return (
    <>
      {isStatus ? (
        !isManagerData ? (
          <>
            <S.DivNew>
              <ButtonNew propClickAction={handleNewLink} propText="New Link" />
            </S.DivNew>
            <Table
              propColumns={objColumns}
              propData={lstData}
              propDblClickRedirect="undefined"
              propDblClickAction={handleViewLink}
            />
          </>
        ) : (
          <>
            <S.DivBack>
              <ButtonBack propClickAction={handleListLinks} propText="Back" />
            </S.DivBack>
            <S.DivContent>
              <S.DivInput propW={isManagerDataNew ? '100%' : '60%'}>
                <S.Form onSubmit={handleSubmit} initialData={objLink}>
                  <S.DivInput propW="25%">
                    <small>Status</small>
                    <S.Select
                      name="_medLinkStatus"
                      options={(objLink.medLinkStatus !== 1 && objLink.medLinkStatus !== 3) ? optStatusSimple : optStatus}
                      disabled={objLink.medLinkStatus === 1 || objLink.medLinkStatus === 3}
                    />
                  </S.DivInput>
                  <S.DivInput propW="25%">
                    <small>Date Created</small>
                    <S.Input name="_medLinkDateCreated" disabled />
                  </S.DivInput>
                  <S.DivInput propW="25%">
                    <small>Date Updated</small>
                    <S.Input name="_medLinkDateUpdated" disabled />
                  </S.DivInput>
                  <S.DivInput propW="25%">
                    <small>Rating</small>
                    <RatingView propValue={3.5} />
                    {/* <S.Input name="medRating" disabled /> */}
                  </S.DivInput>
                  <S.DivInput propW="25%">
                    <small>Type</small>
                    <S.ButtonTypeMedia>
                      {/* kes-dev-prod */}
                      <div onClick={() => setTypeMedia('Trailer')} className={`${typeMedia === 'Trailer' ? 'isSelected' : ''}`}>Trailer</div>
                      <div onClick={() => setTypeMedia('Film')} className={`${typeMedia === 'Film' ? 'isSelected' : ''}`}>Film</div>
                    </S.ButtonTypeMedia>
                  </S.DivInput>
                  <S.DivInput propW="25%" className={typeMedia !== 'Trailer' ? 'not-visible' : ''}>
                    <small>Control</small>
                    <S.Input type="number" min="0" max="99" name="_medLinkControlNull" disabled={true} />
                  </S.DivInput>
                  <S.DivInput propW="25%" className={typeMedia !== 'Trailer' ? 'not-visible' : ''}>
                    <small>Season</small>
                    <S.Input type="number" min="0" max="99" name="_medLinkSeasonNull" disabled={true} />
                  </S.DivInput>
                  <S.DivInput propW="25%" className={typeMedia !== 'Trailer' ? 'not-visible' : ''}>
                    <small>Episode</small>
                    <S.Input type="number" min="0" max="99" name="_medLinkEpisodeNull" disabled={true} />
                  </S.DivInput>
                  <S.DivInput propW="25%" className={typeMedia === 'Trailer' ? 'not-visible' : ''}>
                    <small>Control</small>
                    <S.Input type="number" min="0" max="99" name="medLinkControl" disabled={false} /> {/* kes-dev-prod */}
                  </S.DivInput>
                  <S.DivInput propW="25%" className={typeMedia === 'Trailer' ? 'not-visible' : ''}>
                    <small>Season</small>
                    <S.Input type="number" min="0" max="99" name="medLinkSeason" disabled={false} /> {/* kes-dev-prod */}
                  </S.DivInput>
                  <S.DivInput propW="25%" className={typeMedia === 'Trailer' ? 'not-visible' : ''}>
                    <small>Episode</small>
                    <S.Input type="number" min="0" max="99" name="medLinkEpisode" disabled={false} /> {/* kes-dev-prod */}
                  </S.DivInput>
                  <S.DivInput propW="25%">
                    <small>Key</small>
                    <S.Input name="medKey" disabled={true} />
                  </S.DivInput>
                  <S.DivInput propW="75%">
                    <small>Title</small>
                    <S.Input name="medTitle" disabled={false} />
                  </S.DivInput>
                  <S.DivInput propW="100%">
                    <small>Description</small>
                    <S.Input name="medDescription" disabled={false} />
                  </S.DivInput>
                  <S.DivInput propW="100%">
                    <small>Movie AWS</small>
                    <S.Input name="movId" disabled={true} />
                  </S.DivInput>
                  <S.DivButton>
                    <ButtonLoader propTitle="SAVE" propSpinner={false} />
                  </S.DivButton>
                </S.Form>
              </S.DivInput>
              {!isManagerDataNew && (
                <>
                  <S.DivInput propBorder propW="20%">
                    <S.DivInput propW="100%">
                      <S.Title>
                        Thumbnail Frame
                        <br />
                        <small>1920px x 1080px</small></S.Title>
                      {/* <ButtonAdd propClickAction={handleViewArtist} /> */}
                    </S.DivInput>
                    <S.DivInput propW="100%">
                      <S.LabelInputImageFrame htmlFor="file-frame">
                        <img
                          src={objTemp.medLinkThumbnailFrame}
                          alt="Thumbnail Frame"
                          onError={() =>
                            setTemp(prevState => {
                              return {
                                ...prevState,
                                tmpLinkThumbnailFrame: objDefault.medLinkThumbnailFrame,
                              };
                            })
                          }
                        />
                        <input
                          id="file-frame"
                          type="file"
                          accept="image/*"
                          onChange={e => handleChangeFileFrame(e.target.files)}
                        />
                      </S.LabelInputImageFrame>
                    </S.DivInput>
                  </S.DivInput>
                  <S.DivInput propBorder propW="20%">
                    <S.DivInput propW="100%">
                      <S.Title>
                        Media Film
                        <br />
                        <small>player</small>
                      </S.Title>
                      {/* <ButtonAdd propClickAction={handleViewArtist} /> */}
                    </S.DivInput>
                    <S.DivInput propW="100%">
                      {hlsLinkPlayer ? (
                        <ReactPlayer
                          ref={player}
                          url={hlsLinkPlayer}
                          playing={false}
                          controls
                          // light
                          width="100%"
                          height="100%"
                          // pip={true}
                          onStart={() => {
                            console.log('start');
                            // setError(false);
                          }}
                          onError={e => {
                            console.error(e);
                            // setError(true);
                          }}
                        />
                      ) : (
                        <h1>Error Player</h1>
                      )}
                    </S.DivInput>
                    <S.DivInput propW="100%">
                      <Dropzone
                        disabled={!isDropzone || objLink.medLinkStatus === 1}
                        onDropAccepted={mediaHandleUpload}
                        accept={['video/*']}
                        multiple={false}
                        onDropRejected={mediaHandleUploadError}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <S.Dropzone
                            propEnabled={isDropzone}
                            // className={
                            //   !isDropzone
                            //     ? classes.divDropzone
                            //     : classes.divDropzoneDisabled
                            // }
                            {...getRootProps()}
                          >
                            {/* getRootProps -> Carrega todas as propriedades e adiciona
                              ... -> adiciona todo o conteudo mais esse */}
                            <input {...getInputProps()} />

                            <p>
                              {isDropzone && objLink.medLinkStatus !== 1
                                ? 'Drag the file or click here!'
                                : objLink.medLinkStatus === 1 ? 'Your media is being processed!' : 'Uploading ... (please wait)'}
                            </p>
                          </S.Dropzone>
                        )}
                      </Dropzone>
                    </S.DivInput>
                    <S.DivInput propW="100%">
                      <S.DivCheck>
                        Update main thumbnail?
                        <input
                          checked={isThumbnailGenerateMain}
                          onChange={e => setThumbnailGenerateMain(e.target.checked)}
                          type="checkbox"
                        />
                      </S.DivCheck>
                    </S.DivInput>
                  </S.DivInput>
                </>
              )}
            </S.DivContent>
            {modUpload && (
              <S.Modal>
                <S.ModalDivUpload>
                  <S.ModalHeader>Upload Progress</S.ModalHeader>
                  <S.Hr />
                  {upProgress === 100 ? (
                    <S.ProgressImg propPadding src={imgSuccess} alt="..." />
                  ) : (
                    <S.ProgressImg
                      propPadding={false}
                      src={!isSearch ? imgLoading : imgSearch}
                      alt="..."
                    />
                  )}
                  <CustomLinearProgress
                    variant="determinate"
                    color="primary"
                    value={upProgress}
                  />
                  {/* <S.ModalFooter>
                    Update main thumbnail?
                    <input
                      checked={isThumbnailGenerateMain}
                      onChange={e => setThumbnailGenerateMain(e.target.checked)}
                      type="checkbox"
                    />
                  </S.ModalFooter> */}
                </S.ModalDivUpload>
              </S.Modal>
            )}
            {modArtist && (
              <S.Modal>
                <S.ModalDivArtist>
                  <S.ModalHeader>Insert Artist</S.ModalHeader>
                  <S.Hr />
                  <br />
                  <S.DivBack>
                    <ButtonBack
                      propClickAction={handleCloseArtist}
                      propText="Cancel"
                    />
                  </S.DivBack>
                  <Table
                    propColumns={objColumnsArtist}
                    propData={lstArtist}
                    propDblClickRedirect="undefined"
                    propDblClickAction={handleAddArtist}
                  />
                </S.ModalDivArtist>
              </S.Modal>
            )}
          </>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

Link.defaultProps = {
  propData: {},
};

Link.propTypes = {
  propData: PropTypes.object,
};

export default Link;
