import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';
import { authSignIn } from '../../services/auth';

import ButtonLoaderAnimated from '../../components/ButtonLoaderAnimated';
import ButtonLoader from '../../components/ButtonLoader';

import imgLogo from '../../assets/images/logo.png';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const Login = () => {
  const [isLoading, setLoading] = useState(false);

  const history = useHistory();

  const auxValidate = objData => {
    let varValidate = true;
    if (objData.varEmail === '') {
      varValidate = false;
      N.error('Enter email', 'Login Error');
    }
    if (objData.varPassword === '') {
      varValidate = false;
      N.error('Enter password', 'Login Error');
    }
    return varValidate;
  };

  const handleSubmit = async objData => {
    /**
     * {
     *   email: 'email@example.com',
     *   password: '123456'
     * }
     */

    // N.info('Success message');
    // N.success('Success message', 'Title here');
    // N.warning('Warning message', 'Close after');
    // N.error('Error message', 'Click me!');

    try {
      if (auxValidate(objData)) {
        console.log(objData);
        setLoading(true);
        const resSignIn = await authSignIn(
          objData.varEmail.toLowerCase(),
          objData.varPassword
        );
        if (resSignIn.resStatus) {
          N.success('Login Success');
          setTimeout(() => {
            history.push('/');
          }, 1500);
        } else {
          N.error(resSignIn.resMessage, 'Login Error');
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      N.error('Error signing in');
      setLoading(false);
    }
  };

  const auxGoSignUd = () => {
    history.push('/register');
  };

  return (
    <>
      <S.Container>
        <S.DivWrapper>
          <S.Img src={imgLogo} alt="Logo" />
          <S.Form onSubmit={handleSubmit}>
            <S.DivInput>
              <small>Email</small>
              <S.Input name="varEmail" disabled={isLoading} />
            </S.DivInput>
            <S.DivInput>
              <small>Senha</small>
              <S.Input
                name="varPassword"
                type="password"
                disabled={isLoading}
              />
            </S.DivInput>
            <S.DivButton>
              <ButtonLoaderAnimated
                propTitle="LOGIN"
                propSpinner={isLoading}
              />
            </S.DivButton>
          </S.Form>
          {/* <S.DivButtonSignUp>
            <span>Not a member? Sign UP today!</span>
            <ButtonLoader
              propTitle="SIGN UP"
              propSpinner={false}
              propDisabled={false}
              propAction={auxGoSignUd}
            />
          </S.DivButtonSignUp> */}
        </S.DivWrapper>
      </S.Container>
      <NotificationContainer />
    </>
  );
};

export default Login;
