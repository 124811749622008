/*eslint-disable*/
import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// @material-ui/icons
import { CloudDownload } from '@material-ui/icons';
import {
  PermMedia,
  AccountBox,
  ExitToApp,
  SupervisedUserCircle,
  Category,
  Album,
  LibraryMusic,
  PeopleAlt,
  AutoAwesomeMosaic
} from '@styled-icons/material';
import { Category as Genre } from '@styled-icons/boxicons-solid';
import { PhotoVideo } from '@styled-icons/fa-solid';
import { Info } from '@styled-icons/octicons';
import { LiveTv } from '@styled-icons/material';
import { Museum, PriceChange } from '@styled-icons/material-rounded';
// core components
import Button from '../CustomButtons';

import styles from './styles';
import * as S from './custom';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/user"
        >
          <AccountBox className={classes.icons} /> <S.Span>User</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/influencer"
        >
          <SupervisedUserCircle className={classes.icons} /> <S.Span>Influencer</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItemSeparator}>
        <S.Separator />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/plan"
        >
          <PriceChange className={classes.icons} /> <S.Span>Plan</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItemSeparator}>
        <S.Separator />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/category"
        >
          <Category className={classes.icons} /> <S.Span>Category</S.Span>
        </Button>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/live"
        >
          <LiveTv className={classes.icons} /> <S.Span>Lives</S.Span>
        </Button>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/catalog"
        >
          <PermMedia className={classes.icons} /> <S.Span>Catalogs</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/media"
        >
          <PhotoVideo className={classes.icons} /> <S.Span>Medias</S.Span>
        </Button>
      </ListItem>
      {/* <ListItem className={classes.listItemSeparator}>
        <S.Separator />
      </ListItem>
      <ListItem className={classes.listItemSeparator}>
        <S.Separator />
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/genre"
        >
          <Genre className={classes.icons} /> <S.Span>Genre</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/label"
        >
          <Museum className={classes.icons} />{' '}
          <S.Span>Labels</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/artist"
        >
          <PeopleAlt className={classes.icons} /> <S.Span>Artist</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/album"
        >
          <Album className={classes.icons} /> <S.Span>Album</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/song"
        >
          <LibraryMusic className={classes.icons} /> <S.Span>Song</S.Span>
        </Button>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/label"
        >
          <Museum className={classes.icons} />{' '}
          <S.Span>Labels</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/artist"
        >
          <PeopleAlt className={classes.icons} /> <S.Span>Artist</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/album"
        >
          <Album className={classes.icons} /> <S.Span>Album</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/song"
        >
          <LibraryMusic className={classes.icons} /> <S.Span>Song</S.Span>
        </Button>
      </ListItem> */}
      <ListItem className={classes.listItemSeparator}>
        <S.Separator />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/about"
        >
          <Info className={classes.icons} /> <S.Span>About</S.Span>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          propLink="/logout"
        >
          <ExitToApp className={classes.icons} /> <S.Span>Logout</S.Span>
        </Button>
      </ListItem>
    </List>
  );
}
