import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { format } from 'date-fns';

import api from '../../services/api';

import ReportsItem from '../ReportsItem';
import Loader from '../Loader';

import * as S from './styles';

export default function Reports() {
  const dateNow = new Date().toISOString();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState({
    // ALBUMS: {
    //   amountTotal: 0,
    //   REPORTS: {
    //     amountTotal: {
    //       value: [],
    //       date: [],
    //     },
    //     totalRegister: {
    //       value: [],
    //       date: [],
    //     },
    //   },
    // },
    // ARTISTS: {
    //   amountTotal: 0,
    //   REPORTS: {
    //     amountTotal: {
    //       value: [],
    //       date: [],
    //     },
    //     totalRegister: {
    //       value: [],
    //       date: [],
    //     },
    //   },
    // },
    // GENRES: {
    //   amountTotal: 0,
    //   REPORTS: {
    //     amountTotal: {
    //       value: [],
    //       date: [],
    //     },
    //     totalRegister: {
    //       value: [],
    //       date: [],
    //     },
    //   },
    // },
    CATALOGS: {
      amountTotal: 0,
      REPORTS: {
        amountTotal: {
          value: [],
          date: [],
        },
        totalRegister: {
          value: [],
          date: [],
        },
      },
    },
    CATEGORIES: {
      amountTotal: 0,
      REPORTS: {
        amountTotal: {
          value: [],
          date: [],
        },
        totalRegister: {
          value: [],
          date: [],
        },
      },
    },
    // LABELS: {
    //   amountTotal: 0,
    //   REPORTS: {
    //     amountTotal: {
    //       value: [],
    //       date: [],
    //     },
    //     totalRegister: {
    //       value: [],
    //       date: [],
    //     },
    //   },
    // },
    LIVES: {
      amountTotal: 0,
      REPORTS: {
        amountTotal: {
          value: [],
          date: [],
        },
        totalRegister: {
          value: [],
          date: [],
        },
      },
    },
    MOVIES: {
      amountTotal: 0,
      REPORTS: {
        amountTotal: {
          value: [],
          date: [],
        },
        totalRegister: {
          value: [],
          date: [],
        },
      },
    },
    // SONGS: {
    //   amountTotal: 0,
    //   REPORTS: {
    //     amountTotal: {
    //       value: [],
    //       date: [],
    //     },
    //     totalRegister: {
    //       value: [],
    //       date: [],
    //     },
    //   },
    // },
    TVSHOWS: {
      amountTotal: 0,
      REPORTS: {
        amountTotal: {
          value: [],
          date: [],
        },
        totalRegister: {
          value: [],
          date: [],
        },
      },
    },
    INFLUENCERS: {
      amountTotal: 0,
      REPORTS: {
        amountTotal: {
          value: [],
          date: [],
        },
        totalRegister: {
          value: [],
          date: [],
        },
      },
    },
    USERS: {
      amountTotal: 0,
      amountWithoutSubscription: 0,
      amountWithSubscription: 0,
      totalRegister: 0,
      totalCancellations: 0,
      REPORTS: {
        amountTotal: {
          value: [],
          date: [],
        },
        amountWithoutSubscription: {
          value: [],
          date: [],
        },
        amountWithSubscription: {
          value: [],
          date: [],
        },
        totalRegister: {
          value: [],
          date: [],
        },
        totalCancellations: {
          value: [],
          date: [],
        },
      },
    },
    date: new Date().toISOString(),
    dtDifference: 0,
  });

  const chaOptions1 = {
    title: {
      text: 'Users Amount',
      align: 'left',
    },
    chart: {
      height: 285,
      type: 'radialBar',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '17px',
          },
          value: {
            formatter(val) {
              return parseInt(val);
            },
            color: '#1c242e',
            fontSize: '25px',
            show: true,
          },
          total: {
            show: true,
            label: 'Users',
            color: '#888888',
            fontSize: '17px',
            fontWeight: 400,
            formatter(w) {
              return `${data.USERS.amountTotal}`;
            },
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#ABE5A1'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['Users'],
  };
  const chaOptions238 = {
    title: {
      text: 'Total registered',
      align: 'left',
    },
    chart: {
      height: 285,
      type: 'area',
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2021-06-19T00:00:00.000Z',
        '2021-06-20T01:00:00.000Z',
        '2021-06-21T02:00:00.000Z',
        '2021-06-22T03:00:00.000Z',
        '2021-06-23T04:00:00.000Z',
        '2021-06-24T05:00:00.000Z',
        '2021-06-25T06:00:00.000Z',
      ],
    },
    tooltip: {
      x: {
        format: 'MM/dd/yy',
      },
      y: {
        formatter: undefined,
        title: {
          formatter: seriesName => seriesName,
        },
      },
      marker: {
        show: true,
      },
    },
  };
  const chaOptions45 = {
    title: {
      text: 'Videos Amount',
      align: 'left',
    },
    chart: {
      height: 350,
      type: 'bar',
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: 2,
    },
    grid: {
      row: {
        colors: ['#fff', '#f2f2f2'],
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: ['Catalog', 'Lives', 'Movie', 'TV Show'],
      tickPlacement: 'on',
    },
    yaxis: {
      title: {
        text: 'Amount',
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
  };

  function auxGenerateMonth(month) {
    month += 1;
    return month <= 9 ? `0${month}` : `${month}`;
  }

  function auxGenerateDate(date) {
    return date <= 9 ? `0${date}` : `${date}`;
  }

  useEffect(() => {
    async function loadData() {
      const today = new Date();
      const strToday = `${today.getFullYear()}-${auxGenerateMonth(
        today.getMonth()
      )}-${auxGenerateDate(today.getDate())}`;
      const resGetData = await api(
        'GET',
        `/report/dash/filter?start=${strToday}&end=${strToday}&tmp=null`,
        null
      );
      const {
        data: { resData },
      } = resGetData;
      console.log(`/report/dash/filter?start=${strToday}&end=${strToday}&tmp=null`);
      // console.log(resData);

      if (resData !== null) {
        const tmpData = {
          // ALBUMS: {
          //   amountTotal: 0,
          //   REPORTS: {
          //     amountTotal: {
          //       value: [],
          //       date: [],
          //     },
          //     totalRegister: {
          //       value: [],
          //       date: [],
          //     },
          //   },
          // },
          // ARTISTS: {
          //   amountTotal: 0,
          //   REPORTS: {
          //     amountTotal: {
          //       value: [],
          //       date: [],
          //     },
          //     totalRegister: {
          //       value: [],
          //       date: [],
          //     },
          //   },
          // },
          CATALOGS: {
            amountTotal: 0,
            REPORTS: {
              amountTotal: {
                value: [],
                date: [],
              },
              totalRegister: {
                value: [],
                date: [],
              },
            },
          },
          // LABELS: {
          //   amountTotal: 0,
          //   REPORTS: {
          //     amountTotal: {
          //       value: [],
          //       date: [],
          //     },
          //     totalRegister: {
          //       value: [],
          //       date: [],
          //     },
          //   },
          // },
          // GENRES: {
          //   amountTotal: 0,
          //   REPORTS: {
          //     amountTotal: {
          //       value: [],
          //       date: [],
          //     },
          //     totalRegister: {
          //       value: [],
          //       date: [],
          //     },
          //   },
          // },
          CATEGORIES: {
            amountTotal: 0,
            REPORTS: {
              amountTotal: {
                value: [],
                date: [],
              },
              totalRegister: {
                value: [],
                date: [],
              },
            },
          },
          LIVES: {
            amountTotal: 0,
            REPORTS: {
              amountTotal: {
                value: [],
                date: [],
              },
              totalRegister: {
                value: [],
                date: [],
              },
            },
          },
          MOVIES: {
            amountTotal: 0,
            REPORTS: {
              amountTotal: {
                value: [],
                date: [],
              },
              totalRegister: {
                value: [],
                date: [],
              },
            },
          },
          // SONGS: {
          //   amountTotal: 0,
          //   REPORTS: {
          //     amountTotal: {
          //       value: [],
          //       date: [],
          //     },
          //     totalRegister: {
          //       value: [],
          //       date: [],
          //     },
          //   },
          // },
          TVSHOWS: {
            amountTotal: 0,
            REPORTS: {
              amountTotal: {
                value: [],
                date: [],
              },
              totalRegister: {
                value: [],
                date: [],
              },
            },
          },
          INFLUENCERS: {
            amountTotal: 0,
            REPORTS: {
              amountTotal: {
                value: [],
                date: [],
              },
              totalRegister: {
                value: [],
                date: [],
              },
            },
          },
          USERS: {
            amountTotal: 0,
            amountWithoutSubscription: 0,
            amountWithSubscription: 0,
            totalRegister: 0,
            totalCancellations: 0,
            REPORTS: {
              amountTotal: {
                value: [],
                date: [],
              },
              amountWithoutSubscription: {
                value: [],
                date: [],
              },
              amountWithSubscription: {
                value: [],
                date: [],
              },
              totalRegister: {
                value: [],
                date: [],
              },
              totalCancellations: {
                value: [],
                date: [],
              },
            },
          },
          // SOLD: {
          //   ALBUMS: {
          //     amountTotal: 0,
          //     totalRegister: 0,
          //     REPORTS: {
          //       amountTotal: {
          //         value: [],
          //         date: [],
          //       },
          //       totalRegister: {
          //         value: [],
          //         date: [],
          //       },
          //     },
          //   },
          //   SONGS: {
          //     amountTotal: 0,
          //     totalRegister: 0,
          //     REPORTS: {
          //       amountTotal: {
          //         value: [],
          //         date: [],
          //       },
          //       totalRegister: {
          //         value: [],
          //         date: [],
          //       },
          //     },
          //   },
          // },
          date: new Date().toISOString(),
          dtDifference: 0,
        };

        tmpData.USERS = { ...tmpData.USERS, ...resData.NOW.USERS };
        tmpData.INFLUENCERS = {
          ...tmpData.INFLUENCERS,
          ...resData.NOW.INFLUENCERS,
        };
        tmpData.CATALOGS = { ...tmpData.CATALOGS, ...resData.NOW.CATALOGS };
        tmpData.LIVES = { ...tmpData.LIVES, ...resData.NOW.LIVES };
        tmpData.MOVIES = { ...tmpData.MOVIES, ...resData.NOW.MOVIES };
        tmpData.TVSHOWS = { ...tmpData.TVSHOWS, ...resData.NOW.TVSHOWS };
        // tmpData.GENRES = { ...tmpData.GENRES, ...resData.NOW.GENRES };
        tmpData.CATEGORIES = { ...tmpData.CATEGORIES, ...resData.NOW.CATEGORIES };
        // tmpData.LABELS = { ...tmpData.LABELS, ...resData.NOW.LABELS };
        // tmpData.ARTISTS = { ...tmpData.ARTISTS, ...resData.NOW.ARTISTS };
        // tmpData.ALBUMS = { ...tmpData.ALBUMS, ...resData.NOW.ALBUMS };
        // tmpData.SONGS = { ...tmpData.SONGS, ...resData.NOW.SONGS };

        // tmpData.SOLD.ALBUMS = {
        //   ...tmpData.SOLD.ALBUMS,
        //   ...resData.NOW.SOLD.ALBUMS,
        // };
        // tmpData.SOLD.SONGS = {
        //   ...tmpData.SOLD.SONGS,
        //   ...resData.NOW.SOLD.SONGS,
        // };

        for (let i = 0; i < resData.REPORTS.length; i++) {
          const repData = JSON.parse(resData.REPORTS[i].repData);
          const dateInfo = new Date(resData.REPORTS[i].repKey).toISOString();

          // =======================================================================================
          // USERS
          tmpData.USERS.REPORTS.amountTotal.value.push(
            repData.USERS.amountTotal
          );
          tmpData.USERS.REPORTS.amountTotal.date.push(dateInfo);

          tmpData.USERS.REPORTS.amountWithoutSubscription.value.push(
            repData.USERS.amountWithoutSubscription
          );
          tmpData.USERS.REPORTS.amountWithoutSubscription.date.push(dateInfo);

          tmpData.USERS.REPORTS.amountWithSubscription.value.push(
            repData.USERS.amountWithSubscription
          );
          tmpData.USERS.REPORTS.amountWithSubscription.date.push(dateInfo);

          tmpData.USERS.REPORTS.totalRegister.value.push(
            repData.USERS.totalRegister
          );
          tmpData.USERS.REPORTS.totalRegister.date.push(dateInfo);

          tmpData.USERS.REPORTS.totalCancellations.value.push(
            repData.USERS.totalCancellations
          );
          tmpData.USERS.REPORTS.totalCancellations.date.push(dateInfo);

          // =======================================================================================
          // INFLUENCERS
          if (repData.INFLUENCERS) {
            tmpData.INFLUENCERS.REPORTS.amountTotal.value.push(
              repData.INFLUENCERS.amountTotal
            );
            tmpData.INFLUENCERS.REPORTS.amountTotal.date.push(dateInfo);

            tmpData.INFLUENCERS.REPORTS.totalRegister.value.push(
              repData.INFLUENCERS.totalRegister
            );
            tmpData.INFLUENCERS.REPORTS.totalRegister.date.push(dateInfo);
          } else {
            tmpData.INFLUENCERS.REPORTS.amountTotal.value.push(2);
            tmpData.INFLUENCERS.REPORTS.amountTotal.date.push(dateInfo);
            tmpData.INFLUENCERS.REPORTS.totalRegister.value.push(0);
            tmpData.INFLUENCERS.REPORTS.totalRegister.date.push(dateInfo);
          }

          // =======================================================================================
          // CATALOGS
          tmpData.CATALOGS.REPORTS.amountTotal.value.push(
            repData.CATALOGS.amountTotal
          );
          tmpData.CATALOGS.REPORTS.amountTotal.date.push(dateInfo);

          tmpData.CATALOGS.REPORTS.totalRegister.value.push(
            repData.CATALOGS.totalRegister
          );
          tmpData.CATALOGS.REPORTS.totalRegister.date.push(dateInfo);

          // =======================================================================================
          // CATEGORIES
          tmpData.CATEGORIES.REPORTS.amountTotal.value.push(
            repData.CATEGORIES.amountTotal
          );
          tmpData.CATEGORIES.REPORTS.amountTotal.date.push(dateInfo);

          tmpData.CATEGORIES.REPORTS.totalRegister.value.push(
            repData.CATEGORIES.totalRegister
          );
          tmpData.CATEGORIES.REPORTS.totalRegister.date.push(dateInfo);

          // =======================================================================================
          // LIVES
          tmpData.LIVES.REPORTS.amountTotal.value.push(
            repData.LIVES.amountTotal
          );
          tmpData.LIVES.REPORTS.amountTotal.date.push(dateInfo);

          tmpData.LIVES.REPORTS.totalRegister.value.push(
            repData.LIVES.totalRegister
          );
          tmpData.LIVES.REPORTS.totalRegister.date.push(dateInfo);

          // =======================================================================================
          // MOVIES
          tmpData.MOVIES.REPORTS.amountTotal.value.push(
            repData.MOVIES.amountTotal
          );
          tmpData.MOVIES.REPORTS.amountTotal.date.push(dateInfo);

          tmpData.MOVIES.REPORTS.totalRegister.value.push(
            repData.MOVIES.totalRegister
          );
          tmpData.MOVIES.REPORTS.totalRegister.date.push(dateInfo);

          // =======================================================================================
          // TVSHOWS
          tmpData.TVSHOWS.REPORTS.amountTotal.value.push(
            repData.TVSHOWS.amountTotal
          );
          tmpData.TVSHOWS.REPORTS.amountTotal.date.push(dateInfo);

          tmpData.TVSHOWS.REPORTS.totalRegister.value.push(
            repData.TVSHOWS.totalRegister
          );
          tmpData.TVSHOWS.REPORTS.totalRegister.date.push(dateInfo);

          // =======================================================================================
          // LABELS
          // tmpData.LABELS.REPORTS.amountTotal.value.push(
          //   repData.LABELS.amountTotal
          // );
          // tmpData.LABELS.REPORTS.amountTotal.date.push(dateInfo);

          // tmpData.LABELS.REPORTS.totalRegister.value.push(
          //   repData.LABELS.totalRegister
          // );
          // tmpData.LABELS.REPORTS.totalRegister.date.push(dateInfo);

          // =======================================================================================
          // ARTISTS
          // tmpData.ARTISTS.REPORTS.amountTotal.value.push(
          //   repData.ARTISTS.amountTotal
          // );
          // tmpData.ARTISTS.REPORTS.amountTotal.date.push(dateInfo);

          // tmpData.ARTISTS.REPORTS.totalRegister.value.push(
          //   repData.ARTISTS.totalRegister
          // );
          // tmpData.ARTISTS.REPORTS.totalRegister.date.push(dateInfo);

          // =======================================================================================
          // ALBUMS
          // tmpData.ALBUMS.REPORTS.amountTotal.value.push(
          //   repData.ALBUMS.amountTotal
          // );
          // tmpData.ALBUMS.REPORTS.amountTotal.date.push(dateInfo);

          // tmpData.ALBUMS.REPORTS.totalRegister.value.push(
          //   repData.ALBUMS.totalRegister
          // );
          // tmpData.ALBUMS.REPORTS.totalRegister.date.push(dateInfo);

          // =======================================================================================
          // SONGS
          // tmpData.SONGS.REPORTS.amountTotal.value.push(
          //   repData.SONGS.amountTotal
          // );
          // tmpData.SONGS.REPORTS.amountTotal.date.push(dateInfo);

          // tmpData.SONGS.REPORTS.totalRegister.value.push(
          //   repData.SONGS.totalRegister
          // );
          // tmpData.SONGS.REPORTS.totalRegister.date.push(dateInfo);

          // =======================================================================================
          // GENRES
          // tmpData.GENRES.REPORTS.amountTotal.value.push(
          //   repData.GENRES.amountTotal
          // );
          // tmpData.GENRES.REPORTS.amountTotal.date.push(dateInfo);

          // tmpData.GENRES.REPORTS.totalRegister.value.push(
          //   repData.GENRES.totalRegister
          // );
          // tmpData.GENRES.REPORTS.totalRegister.date.push(dateInfo);

          // =======================================================================================
          // SOLD

          // =======================================================================================
          // SOLD ALBUMS

          // tmpData.SOLD.ALBUMS.REPORTS.amountTotal.value.push(
          //   repData.SOLD.ALBUMS.amountTotal
          // );
          // tmpData.SOLD.ALBUMS.REPORTS.amountTotal.date.push(dateInfo);

          // tmpData.SOLD.ALBUMS.REPORTS.totalRegister.value.push(
          //   repData.SOLD.ALBUMS.totalRegister
          // );
          // tmpData.SOLD.ALBUMS.REPORTS.totalRegister.date.push(dateInfo);

          // // =======================================================================================
          // // SOLD SONGS

          // tmpData.SOLD.SONGS.REPORTS.amountTotal.value.push(
          //   repData.SOLD.SONGS.amountTotal
          // );
          // tmpData.SOLD.SONGS.REPORTS.amountTotal.date.push(dateInfo);

          // tmpData.SOLD.SONGS.REPORTS.totalRegister.value.push(
          //   repData.SOLD.SONGS.totalRegister
          // );
          // tmpData.SOLD.SONGS.REPORTS.totalRegister.date.push(dateInfo);

          // =======================================================================================
          // =======================================================================================
          // =======================================================================================
        }

        // =========================================================================================
        // USERS
        // Remove firts and insert last (today)
        tmpData.USERS.REPORTS.totalRegister.value.shift();
        tmpData.USERS.REPORTS.totalRegister.value.push(
          resData.NOW.USERS.totalRegister
        );
        tmpData.USERS.REPORTS.totalRegister.date.shift();
        tmpData.USERS.REPORTS.totalRegister.date.push(new Date().toISOString());

        tmpData.USERS.REPORTS.amountWithoutSubscription.value.shift();
        tmpData.USERS.REPORTS.amountWithoutSubscription.value.push(
          resData.NOW.USERS.amountWithoutSubscription
        );
        tmpData.USERS.REPORTS.amountWithoutSubscription.date.shift();
        tmpData.USERS.REPORTS.amountWithoutSubscription.date.push(
          new Date().toISOString()
        );
        tmpData.USERS.REPORTS.amountWithSubscription.value.shift();
        tmpData.USERS.REPORTS.amountWithSubscription.value.push(
          resData.NOW.USERS.amountWithSubscription
        );
        tmpData.USERS.REPORTS.amountWithSubscription.date.shift();
        tmpData.USERS.REPORTS.amountWithSubscription.date.push(
          new Date().toISOString()
        );

        // =========================================================================================
        // CATALOGS
        // Remove firts and insert last (today)
        tmpData.CATALOGS.REPORTS.totalRegister.value.shift();
        tmpData.CATALOGS.REPORTS.totalRegister.value.push(
          resData.NOW.CATALOGS.totalRegister
        );
        tmpData.CATALOGS.REPORTS.totalRegister.date.shift();
        tmpData.CATALOGS.REPORTS.totalRegister.date.push(
          new Date().toISOString()
        );

        // =========================================================================================
        // CATEGORIES
        // Remove firts and insert last (today)
        tmpData.CATEGORIES.REPORTS.totalRegister.value.shift();
        tmpData.CATEGORIES.REPORTS.totalRegister.value.push(
          resData.NOW.CATEGORIES.totalRegister
        );
        tmpData.CATEGORIES.REPORTS.totalRegister.date.shift();
        tmpData.CATEGORIES.REPORTS.totalRegister.date.push(
          new Date().toISOString()
        );

        // =========================================================================================
        // LIVES
        // Remove firts and insert last (today)
        tmpData.LIVES.REPORTS.totalRegister.value.shift();
        tmpData.LIVES.REPORTS.totalRegister.value.push(
          resData.NOW.LIVES.totalRegister
        );
        tmpData.LIVES.REPORTS.totalRegister.date.shift();
        tmpData.LIVES.REPORTS.totalRegister.date.push(new Date().toISOString());

        // =========================================================================================
        // MOVIES
        // Remove firts and insert last (today)
        tmpData.MOVIES.REPORTS.totalRegister.value.shift();
        tmpData.MOVIES.REPORTS.totalRegister.value.push(
          resData.NOW.MOVIES.totalRegister
        );
        tmpData.MOVIES.REPORTS.totalRegister.date.shift();
        tmpData.MOVIES.REPORTS.totalRegister.date.push(
          new Date().toISOString()
        );

        // =========================================================================================
        // LIVES
        // Remove firts and insert last (today)
        tmpData.TVSHOWS.REPORTS.totalRegister.value.shift();
        tmpData.TVSHOWS.REPORTS.totalRegister.value.push(
          resData.NOW.TVSHOWS.totalRegister
        );
        tmpData.TVSHOWS.REPORTS.totalRegister.date.shift();
        tmpData.TVSHOWS.REPORTS.totalRegister.date.push(
          new Date().toISOString()
        );

        // =========================================================================================
        // LABELS
        // Remove firts and insert last (today)
        // tmpData.LABELS.REPORTS.totalRegister.value.shift();
        // tmpData.LABELS.REPORTS.totalRegister.value.push(
        //   resData.NOW.LABELS.totalRegister
        // );
        // tmpData.LABELS.REPORTS.totalRegister.date.shift();
        // tmpData.LABELS.REPORTS.totalRegister.date.push(
        //   new Date().toISOString()
        // );

        // =========================================================================================
        // ARTISTS
        // Remove firts and insert last (today)
        // tmpData.ARTISTS.REPORTS.totalRegister.value.shift();
        // tmpData.ARTISTS.REPORTS.totalRegister.value.push(
        //   resData.NOW.ARTISTS.totalRegister
        // );
        // tmpData.ARTISTS.REPORTS.totalRegister.date.shift();
        // tmpData.ARTISTS.REPORTS.totalRegister.date.push(
        //   new Date().toISOString()
        // );

        // =========================================================================================
        // ALBUMS
        // Remove firts and insert last (today)
        // tmpData.ALBUMS.REPORTS.totalRegister.value.shift();
        // tmpData.ALBUMS.REPORTS.totalRegister.value.push(
        //   resData.NOW.ALBUMS.totalRegister
        // );
        // tmpData.ALBUMS.REPORTS.totalRegister.date.shift();
        // tmpData.ALBUMS.REPORTS.totalRegister.date.push(
        //   new Date().toISOString()
        // );

        // =========================================================================================
        // SONGS
        // Remove firts and insert last (today)
        // tmpData.SONGS.REPORTS.totalRegister.value.shift();
        // tmpData.SONGS.REPORTS.totalRegister.value.push(
        //   resData.NOW.SONGS.totalRegister
        // );
        // tmpData.SONGS.REPORTS.totalRegister.date.shift();
        // tmpData.SONGS.REPORTS.totalRegister.date.push(new Date().toISOString());

        // =========================================================================================
        // GENRES
        // Remove firts and insert last (today)
        // tmpData.GENRES.REPORTS.totalRegister.value.shift();
        // tmpData.GENRES.REPORTS.totalRegister.value.push(
        //   resData.NOW.GENRES.totalRegister
        // );
        // tmpData.GENRES.REPORTS.totalRegister.date.shift();
        // tmpData.GENRES.REPORTS.totalRegister.date.push(
        //   new Date().toISOString()
        // );

        // =========================================================================================
        // SOLD

        // =========================================================================================
        // SOLD ALBUMS
        // Remove firts and insert last (today)
        // tmpData.SOLD.ALBUMS.REPORTS.totalRegister.value.shift();
        // tmpData.SOLD.ALBUMS.REPORTS.totalRegister.value.push(
        //   resData.NOW.SOLD.ALBUMS.totalRegister
        // );
        // tmpData.SOLD.ALBUMS.REPORTS.totalRegister.date.shift();
        // tmpData.SOLD.ALBUMS.REPORTS.totalRegister.date.push(
        //   new Date().toISOString()
        // );
        // tmpData.SOLD.ALBUMS.REPORTS.amountTotal.value.shift();
        // tmpData.SOLD.ALBUMS.REPORTS.amountTotal.value.push(
        //   resData.NOW.SOLD.ALBUMS.amountTotal
        // );
        // tmpData.SOLD.ALBUMS.REPORTS.amountTotal.date.shift();
        // tmpData.SOLD.ALBUMS.REPORTS.amountTotal.date.push(
        //   new Date().toISOString()
        // );

        // =========================================================================================
        // SOLD SONG
        // Remove firts and insert last (today)
        // tmpData.SOLD.SONGS.REPORTS.totalRegister.value.shift();
        // tmpData.SOLD.SONGS.REPORTS.totalRegister.value.push(
        //   resData.NOW.SOLD.SONGS.totalRegister
        // );
        // tmpData.SOLD.SONGS.REPORTS.totalRegister.date.shift();
        // tmpData.SOLD.SONGS.REPORTS.totalRegister.date.push(
        //   new Date().toISOString()
        // );
        // tmpData.SOLD.SONGS.REPORTS.amountTotal.value.shift();
        // tmpData.SOLD.SONGS.REPORTS.amountTotal.value.push(
        //   resData.NOW.SOLD.SONGS.amountTotal
        // );
        // tmpData.SOLD.SONGS.REPORTS.amountTotal.date.shift();
        // tmpData.SOLD.SONGS.REPORTS.amountTotal.date.push(
        //   new Date().toISOString()
        // );

        // =========================================================================================
        // =========================================================================================
        // =========================================================================================

        // console.log(tmpData);
        setData(tmpData);
        setLoading(false);
      }
    }

    try {
      loadData();
    } catch (error) {
      // setLoading(false);
      // setError(true);
    }
  }, []);

  return (
    <S.Container>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <S.Type>
            <S.Title>USERS</S.Title>
            <S.GridUser>
              {/* <ReportsItem title="Total" value={data.USERS.amountTotal} /> */}
              <Chart
                type="radialBar"
                height="300"
                options={chaOptions1}
                // options={chaOptions238}
                series={[(100 * data.USERS.amountTotal) / 100]}
              />
              <Chart
                type="area"
                height="285"
                options={{
                  ...chaOptions238,
                  ...{
                    title: {
                      text: 'Total registered',
                    },
                    xaxis: {
                      type: 'datetime',
                      categories: data.USERS.REPORTS.totalRegister.date,
                    },
                  },
                }}
                // options={chaOptions238}
                series={[
                  {
                    name: 'total',
                    data: data.USERS.REPORTS.totalRegister.value,
                  },
                ]}
              />
              <Chart
                type="area"
                height="285"
                options={{
                  ...chaOptions238,
                  ...{
                    title: {
                      text: 'Amount users (Subscription)',
                    },
                    xaxis: {
                      type: 'datetime',
                      categories:
                        data.USERS.REPORTS.amountWithoutSubscription.date,
                    },
                  },
                }}
                // options={chaOptions238}
                series={[
                  {
                    name: 'free',
                    data: data.USERS.REPORTS.amountWithoutSubscription.value,
                  },
                  {
                    name: 'pay',
                    data: data.USERS.REPORTS.amountWithSubscription.value,
                  },
                ]}
              />
              {/* <Chart
                type="area"
                height="285"
                options={{
                  ...chaOptions238,
                  ...{
                    title: {
                      text: 'Canceled users (Subscription)',
                    },
                    xaxis: {
                      type: 'datetime',
                      categories: data.USERS.REPORTS.totalCancellations.date,
                    },
                  },
                }}
                // options={chaOptions238}
                series={[
                  {
                    name: 'users',
                    data: data.USERS.REPORTS.totalCancellations.value,
                  },
                ]}
              /> */}
            </S.GridUser>
          </S.Type>
          <S.Type>
            <S.Title>CONTENT</S.Title>
            <S.Grid>
              <ReportsItem
                title="Inlfuencers"
                value={data.INFLUENCERS.amountTotal}
              />
              <Chart
                type="bar"
                height="285"
                options={{
                  ...chaOptions45,
                  ...{
                    title: {
                      text: 'Amount videos',
                    },
                    xaxis: {
                      labels: {
                        rotate: -45,
                      },
                      categories: ['Categories', 'Catalog', 'Lives', 'Movie', 'TV Show'],
                      tickPlacement: 'on',
                    },
                  },
                }}
                // options={chaOptions238}
                series={[
                  {
                    name: 'Amount',
                    data: [
                      data.CATEGORIES.amountTotal,
                      data.CATALOGS.amountTotal,
                      data.LIVES.amountTotal,
                      data.MOVIES.amountTotal,
                      data.TVSHOWS.amountTotal,
                    ],
                  },
                ]}
              />
              {/* <Chart
                type="bar"
                height="285"
                options={{
                  ...chaOptions45,
                  ...{
                    title: {
                      text: 'Amount Musics',
                    },
                    xaxis: {
                      labels: {
                        rotate: -45,
                      },
                      categories: ['Genres', 'Label', 'Artist', 'Album', 'Song'],
                      tickPlacement: 'on',
                    },
                  },
                }}
                // options={chaOptions238}
                series={[
                  {
                    name: 'Amount',
                    data: [
                      data.GENRES.amountTotal,
                      data.LABELS.amountTotal,
                      data.ARTISTS.amountTotal,
                      data.ALBUMS.amountTotal,
                      data.SONGS.amountTotal,
                    ],
                  },
                ]}
              /> */}
              {/* <Chart
                type="bar"
                height="285"
                options={{
                  ...chaOptions45,
                  ...{
                    title: {
                      text: 'Amount Musics',
                    },
                    xaxis: {
                      labels: {
                        rotate: -45,
                      },
                      categories: ['Label', 'Artist', 'Album', 'Song'],
                      tickPlacement: 'on',
                    },
                  },
                }}
                // options={chaOptions238}
                series={[
                  {
                    name: 'Amount',
                    data: [
                      data.LABELS.amountTotal,
                      data.ARTISTS.amountTotal,
                      data.ALBUMS.amountTotal,
                      data.SONGS.amountTotal,
                    ],
                  },
                ]}
              />
              <Chart
                type="area"
                height="285"
                options={{
                  ...chaOptions238,
                  ...{
                    title: {
                      text: 'Amount sold',
                    },
                    xaxis: {
                      type: 'datetime',
                      categories: data.SOLD.ALBUMS.REPORTS.amountTotal.date,
                    },
                  },
                }}
                // options={chaOptions238}
                series={[
                  {
                    name: 'song',
                    data: data.SOLD.SONGS.REPORTS.amountTotal.value,
                  },
                  {
                    name: 'album',
                    data: data.SOLD.ALBUMS.REPORTS.amountTotal.value,
                  },
                ]}
              /> */}
            </S.Grid>
          </S.Type>
        </>
      )}
    </S.Container>
  );
}
