// Engaged Tv ADM
export default {
  info: {
    NAME: 'alive-app',
  },
  api: {
    BASE_URL: 'api.alivethenetwork.tv',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'eb2owq2ru4', // app-dev
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_KFmXQquxH",
    APP_CLIENT_ID: "515db6g6e9p19t060ehka9hj8s",
    IDENTITY_POOL_ID: "us-east-1:6a9e5be8-78d0-4450-ae0c-49f0fc6efef6"
  },
  pinpoint: {
    APP_ID: "1d40ef0e54fa451aad28e9c71fb07a8a",
    REGION: "us-east-1",
  },
  // --------------------------------------------------------------------------------- S3 AUTH -----
  S3_THUMBNAILS_PROFILE: {
    REGION: "us-east-1",
    BUCKET: "alive-thumbnails-profile",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_INFLUENCER: {
    REGION: "us-east-1",
    BUCKET: "alive-thumbnails-influencer",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  // ------------------------------------------------------------------------------- S3 MOVIES -----
  S3_THUMBNAILS_CATEGORY: {
    REGION: "us-east-1",
    BUCKET: "alive-thumbnails-category",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_LIVE: {
    REGION: "us-east-1",
    BUCKET: "alive-thumbnails-live",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_CATALOG: {
    REGION: "us-east-1",
    BUCKET: "alive-thumbnails-catalog",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_MEDIA: {
    REGION: "us-east-1",
    BUCKET: "alive-thumbnails-media",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  // --------------------------------------------------------------------------------- S3 FILM -----
  S3_MEDIA_FILM: {
    REGION: "us-east-1",
    CLOUD_FRONT: "alive-vod-movies-source71e471f1-1ioqii0yfm0r8",
    BUCKET: "alive-vod-movies-source71e471f1-1ioqii0yfm0r8",
    MAX_ATTACHMENT_SIZE: 20000000000, //12 GB
  },
  // -------------------------------------------------------------------------------- S3 MUSIC -----
  S3_THUMBNAILS_GENRE: {
    REGION: "us-east-1",
    BUCKET: "alive-thumbnails-genre",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_LABEL: {
    REGION: "us-east-1",
    BUCKET: "alive-thumbnails-label",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_ARTIST: {
    REGION: "us-east-1",
    BUCKET: "alive-thumbnails-artist",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_ALBUM: {
    REGION: "us-east-1",
    BUCKET: "alive-thumbnails-album",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  // -------------------------------------------------------------------------------- S3 MUSIC -----
  S3_MUSIC: {
    REGION: "us-east-1",
    CLOUD_FRONT: "https://d1qpj0qfcpec2e.cloudfront.net",
    BUCKET: "alive-vod-music",
    MAX_ATTACHMENT_SIZE: 1000000000, //1 GB
  },
};
