import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import CategoryManagerContext from '../../CategoryManagerContext';

import Loader from '../../../../components/Loader';

import Table from '../../../../components/Table';

import 'react-notifications/lib/notifications.css';
// import * as S from './styles';

const Media = ({ propData }) => {
  const history = useHistory();

  const { viewLoading, viewError, viewContent } = useContext(
    CategoryManagerContext
  );
  const [lstData, setDataTable] = useState([]);
  const [objDataMain, setDataMain] = useState(propData);
  const [isStatus, setStatus] = useState(false);

  const objColumns = [
    {
      title: '',
      field: '_medStatus',
      formatter: 'html',
      width: 15,
      align: 'center',
    },
    {
      title: 'F Main',
      field: '_medFeaturedMain',
      formatter: 'html',
      width: 100,
      align: 'center',
    },
    {
      title: 'Featured',
      field: '_medFeatured',
      formatter: 'html',
      width: 110,
      align: 'center',
    },
    {
      title: 'Type',
      field: 'medType',
      headerFilter: 'input',
      width: 110,
      align: 'center',
    },
    { title: 'Title', field: 'medTitle', headerFilter: 'input' },
    { title: 'Categories', field: '_medCategory', headerFilter: 'input' },
    {
      title: 'Created',
      field: '_medDateCreated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Updated',
      field: '_medDateUpdated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
  ];

  const auxFormatData = objData => {
    objData.id = objData.medId;
    objData._medStatus =
      objData.medStatus === 0
        ? '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #ABFF00; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;"></div>'
        : '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #FF0000; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #470101 0 -1px 9px, #ff0000 0 2px 12px;"></div>';
    objData._medFeaturedMain =
      objData.medFeaturedMain === 0
        ? '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #ABFF00; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;"></div>'
        : '';//'<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #FF0000; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #470101 0 -1px 9px, #ff0000 0 2px 12px;"></div>';
    objData._medFeatured =
      objData.medFeatured === 0
        ? '<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #ABFF00; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;"></div>'
        : '';//'<div style="margin: 0 auto; width: 15px; height: 15px; background-color: #FF0000; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #470101 0 -1px 9px, #ff0000 0 2px 12px;"></div>';
    objData._medCategory = '';
    objData.CATEGORY.map((category) => {
      objData._medCategory += ` | ${category.name}`;
    });
    objData._medCategory = objData._medCategory.substr(2);
    objData._medDateCreated = format(
      new Date(objData.medDateCreated),
      'MM/dd/yyyy'
    );
    objData._medDateUpdated = format(
      new Date(objData.medDateUpdated),
      'MM/dd/yyyy'
    );
    return objData;
  };

  useEffect(() => {
    async function loadData() {
      console.log(objDataMain.MEDIAS);
      const tmpData = objDataMain.MEDIAS.length > 0 ? objDataMain.MEDIAS.map(auxFormatData) : [];
      setDataTable(tmpData);

      viewContent();
      setStatus(true);
    }
    try {
      loadData();
    } catch (error) {
      console.error(error);
      viewError();
    }
  }, []);

  return (
    <>
      {isStatus ? (
        <Table
          propColumns={objColumns}
          propData={lstData}
          propDblClickRedirect="/media"
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

Media.defaultProps = {
  propData: {},
};

Media.propTypes = {
  propData: PropTypes.object,
};

export default Media;
