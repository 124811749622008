import { Storage } from 'aws-amplify';
import uuid from 'uuid/v1';

import config from '../config/aws';


export async function fileUploadImg(fileType, fileId, fileSize, file) {
  const filename = `${fileId}---${fileSize}---${Date.now()}.${ext(file.name)}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: config[`S3_THUMBNAILS_${fileType.toUpperCase()}`].BUCKET,
  });

  // const link = await Storage.get(stored.key);
  // console.log(link);

  // Link normal
  // const link = `https://${config[`S3_THUMBNAILS_${fileType.toUpperCase()}`].BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  // Link CDN
  const link = `https://cdn.${fileType.toLowerCase()}.alivethenetwork.tv/${stored.key}`;

  return { key: stored.key, link };
}

export async function fileUploadMedia(fileType, fileId, file, fncProgress) {
  const filename = `${fileId}---${Date.now()}.${ext(file.name)}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: fileType === 'film' ? config.S3_MEDIA_FILM.BUCKET : config.S3_MUSIC.BUCKET,
    progressCallback(progress) {
      console.log(`# Uploaded: ${progress.loaded}/${progress.total}`);
      let varControl = (progress.loaded * 100) / progress.total;
      let strControl = varControl.toFixed(2);
      console.log(`# Uploaded: ${strControl} %`);
      fncProgress(parseFloat(strControl));
    }
  });

  let link = `${fileType === 'film' ? config.S3_MEDIA_FILM.CLOUD_FRONT : config.S3_MUSIC.CLOUD_FRONT}/`;
  link += filename;

  // console.log(link);

  // const link = await Storage.get(stored.key);
  // console.log(link);
  // const link = `https://${config[S3_MEDIA_FILM.BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  return { key: stored.key, filename, link };
}











export async function fileUploadInfluencerImg(fileId, fileType, file) {
  const filename = `${fileId}---${fileType}---${Date.now()}.${ext(file.name)}`;
  // const filename = `${Date.now()}-${uuid()}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: config.S3_THUMBNAILS_INFLUENCER.BUCKET,
  });

  // const link = await Storage.get(stored.key);
  // console.log(link);
  const link = `https://${config.S3_THUMBNAILS_INFLUENCER.BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  return { key: stored.key, link };
}

export async function fileUploadCatalogImg(fileId, fileType, file) {
  const filename = `${fileId}---${fileType}---${Date.now()}.${ext(file.name)}`;
  // const filename = `${Date.now()}-${uuid()}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: config.S3_THUMBNAILS_CATALOG.BUCKET,
  });

  // const link = await Storage.get(stored.key);
  // console.log(link);
  const link = `https://${config.S3_THUMBNAILS_CATALOG.BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  return { key: stored.key, link };
}

export async function fileUploadCategoryImg(fileId, fileType, file) {
  const filename = `${fileId}---${fileType}---${Date.now()}.${ext(file.name)}`;
  // const filename = `${Date.now()}-${uuid()}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: config.S3_THUMBNAILS_CATEGORY.BUCKET,
  });

  // const link = await Storage.get(stored.key);
  // console.log(link);
  const link = `https://${config.S3_THUMBNAILS_CATEGORY.BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  return { key: stored.key, link };
}

export async function fileUploadLabelImg(fileId, fileType, file) {
  const filename = `${fileId}---${fileType}---${Date.now()}.${ext(file.name)}`;
  // const filename = `${Date.now()}-${uuid()}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: config.S3_THUMBNAILS_LABEL.BUCKET,
  });

  // const link = await Storage.get(stored.key);
  // console.log(link);
  const link = `https://${config.S3_THUMBNAILS_LABEL.BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  return { key: stored.key, link };
}

export async function fileUploadArtistImg(fileId, fileType, file) {
  const filename = `${fileId}---${fileType}---${Date.now()}.${ext(file.name)}`;
  // const filename = `${Date.now()}-${uuid()}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: config.S3_THUMBNAILS_ARTIST.BUCKET,
  });

  // const link = await Storage.get(stored.key);
  // console.log(link);
  const link = `https://${config.S3_THUMBNAILS_ARTIST.BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  return { key: stored.key, link };
}

export async function fileUploadAlbumImg(fileId, fileType, file) {
  const filename = `${fileId}---${fileType}---${Date.now()}.${ext(file.name)}`;
  // const filename = `${Date.now()}-${uuid()}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: config.S3_THUMBNAILS_ALBUM.BUCKET,
  });

  // const link = await Storage.get(stored.key);
  // console.log(link);
  const link = `https://${config.S3_THUMBNAILS_ALBUM.BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  return { key: stored.key, link };
}

export async function fileUploadSong(fileId, fileType, file, fncProgress) {
  const filename = `${fileId}---${fileType}---${Date.now()}.${ext(file.name)}`;
  // const filename = `${Date.now()}-${uuid()}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: config.S3_SONG.BUCKET,
    progressCallback(progress) {
      console.log(`# Uploaded: ${progress.loaded}/${progress.total}`);
      let varControl = (progress.loaded * 100) / progress.total;
      let strControl = varControl.toFixed(2);
      console.log(`# Uploaded: ${strControl} %`);
      fncProgress(parseFloat(strControl));
    }
  });

  // const link = await Storage.get(stored.key);
  // console.log(link);
  const link = `https://${config.S3_SONG.BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  return { key: stored.key, link };
}







export async function s3Upload(file) {
  const filename = `${Date.now()}-${uuid()}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    // level: 'public',
    contentType: file.type,
  });
  console.log(stored);

  // const get = await Storage.get(stored);
  // console.log(get);

  return stored.key;
}
export async function fileRead(key) {
  const link = await Storage.get(key);
  // console.log(link);
  return link;
}

export async function fileUploadMediaImg(medId, medType, file) {
  const filename = `${medId}---${medType}---${Date.now()}.${ext(file.name)}`;
  // const filename = `${Date.now()}-${uuid()}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: config.S3_THUMBNAIL.BUCKET,
  });

  // const link = await Storage.get(stored.key);
  // console.log(link);
  const link = `https://${config.S3_THUMBNAIL.BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  return { key: stored.key, link };
}

export async function fileUploadMediaImgRead(key) {
  const link = await Storage.get(key);
  // console.log(link);
  return link;
}

export async function fileUploadMediaMovie(chaId, medId, file, fncProgress) {
  const filename = `${chaId}---${medId}---${Date.now()}.${ext(file.name)}`;
  // const filename = `${Date.now()}-${uuid()}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: config.S3_MOVIE.BUCKET,
    progressCallback(progress) {
      console.log(`# Uploaded: ${progress.loaded}/${progress.total}`);
      let varControl = (progress.loaded * 100) / progress.total;
      let strControl = varControl.toFixed(2);
      console.log(`# Uploaded: ${strControl} %`);
      fncProgress(parseFloat(strControl));
    }
  });

  // const link = await Storage.get(stored.key);
  // console.log(link);
  // const link = `https://${config.S3_MOVIE.BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  return { key: stored.key, filename };
}

export async function fileUploadMediaMovieRead(key) {
  const link = await Storage.get(key);
  // console.log(link);
  return link;
}

function ext(path) {
  var idx = (~-path.lastIndexOf(".") >>> 0) + 2;
  return path.substr(path.lastIndexOf("/") - idx > -3 ? -1 >>> 0 : idx);
}

export async function fileUploadChannelImg(chaId, medType, file) {
  const filename = `${chaId}---${medType}---${Date.now()}.${ext(file.name)}`;
  // const filename = `${Date.now()}-${uuid()}`;

  // const stored = await Storage.vault.put(filename, file, {
  // private s3 "vault"
  const stored = await Storage.put(filename, file, {
    level: "public",
    contentType: file.type,
    bucket: config.S3_THUMBNAILS_PROFILES.BUCKET,
  });

  // const link = await Storage.get(stored.key);
  // console.log(link);
  const link = `https://${config.S3_THUMBNAILS_PROFILES.BUCKET}.s3.amazonaws.com/public/${stored.key}`;

  return { key: stored.key, link };
}

export async function fileUploadChannelImgRead(key) {
  const link = await Storage.get(key);
  // console.log(link);
  return link;
}
