import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';
import InfluencerManagerContext from '../../InfluencerManagerContext';

import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const Info = ({ propData, propNewData }) => {
  const history = useHistory();
  const { viewLoading, viewError, viewContent } = useContext(
    InfluencerManagerContext
  );

  const [objData, setData] = useState(propData);
  // console.log(objData);

  const optStatus = [
    { id: 'Active', title: 'Active' },
    // { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    // { id: 'Blocked', title: 'Blocked' },
  ];
  const optStatusAuth = [
    { id: 'Authenticated', title: 'Authenticated' },
    { id: 'Unauthenticated', title: 'Unauthenticated' },
  ];

  useEffect(() => {
    async function loadData() {
      viewContent();
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const handleSubmit = async data => {
    try {
      if (handleValidate()) {
        if (propNewData) {
          viewLoading();
          console.log(`Data POST`, data);
          const resPostData = await api('POST', `/influencer`, data);
          console.log(resPostData);

          N.success('Successfully created');

          setTimeout(() => {
            history.push(`/influencer/${resPostData.data.resData.infId}`);
            window.location.reload();
          }, 1500);
        } else {
          viewLoading();
          console.log(`Data PUT`, data);
          const resPutData = await api(
            'PUT',
            `/influencer/${objData.infId}/info`,
            data
          );
          console.log(resPutData);

          N.success('Successfully updated');

          setTimeout(() => {
            // history.push('/');
            window.location.reload();
          }, 1500);
        }
      }

      function handleValidate() {
        let varControl = true;

        console.log(data);

        if (data.infId.length !== 3) {
          varControl = false;
          N.error('Code', 'Invalid format');
        }

        if (data._infStatus !== 'Active' && data._infStatus !== 'Disabled') {
          varControl = false;
          N.error('Status', 'Check parameters');
        } else {
          switch (data._infStatus) {
            case 'Active':
              data.infStatus = 0;
              break;
            case 'Disabled':
              data.infStatus = 2;
              break;
            default:
              varControl = false;
              N.error('Error Status', 'Check parameters');
              break;
          }
        }

        if (data.infEmail === '') {
          varControl = false;
          N.error('Email', 'Check parameters');
        }

        if (data.infName === '') {
          varControl = false;
          N.error('Name', 'Check parameters');
        }

        if (data.infAbout === '') {
          varControl = false;
          N.error('About', 'Check parameters');
        }

        return varControl;
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 409) {
        N.error(error.response.data.resMessage);
        viewContent();
      } else {
        N.error('Error save');
        viewContent();
      }
    }
  };

  return (
    <>
      <S.Form onSubmit={handleSubmit} initialData={objData}>
        <S.DivInput propW="25%">
          <small>Code</small>
          <S.Input
            name="infId"
            type="number"
            min="0"
            max="999"
            disabled={!propNewData}
          />
        </S.DivInput>
        <S.DivInput propW="25%">
          <small>Status</small>
          <S.Select name="_infStatus" options={optStatus} disabled={false} />
        </S.DivInput>
        <S.DivInput propW="25%">
          <small>Date Created</small>
          <S.Input name="_infDateCreated" disabled />
        </S.DivInput>
        <S.DivInput propW="25%">
          <small>Date Updated</small>
          <S.Input name="_infDateUpdated" disabled />
        </S.DivInput>
        <S.DivInput propW="100%">
          <small>Name</small>
          <S.Input name="infName" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="35%">
          <small>Email</small>
          <S.Input name="infEmail" type="email" disabled={objData.infEmailAuth === 0} />
        </S.DivInput>
        <S.DivInput propW="30%">
          {propNewData ? (
            <>
              <S.LabelInformation>IMPORTANT</S.LabelInformation>
              <span>It is necessary to have a registered user and use the same email</span>
            </>
            ) : (
              <>
              <small>Authenticated Email</small>
              <S.Select name="_infEmailAuth" options={optStatusAuth} disabled />
            </>
          )}
        </S.DivInput>
        <S.DivInput propW="35%">
          <small>
            User ID
            <S.Link to={`/user/${objData.useId}`}>{!propNewData && '[ view ]'}</S.Link>
          </small>
          <S.Input name="useId" disabled />
        </S.DivInput>
        <S.DivInput propW="100%">
          <small>About</small>
          <S.Input name="infAbout" disabled={false} />
        </S.DivInput>
        <S.DivButton>
          <ButtonLoader propTitle="SAVE" propSpinner={false} />
        </S.DivButton>
      </S.Form>
      <NotificationContainer />
    </>
  );
};

Info.defaultProps = {
  propData: {},
  propNewData: false,
};

Info.propTypes = {
  propData: PropTypes.object,
  propNewData: PropTypes.bool,
};

export default Info;
