import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';
import CategoryManagerContext from '../../CategoryManagerContext';

import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const Info = ({ propData, propNewData, propNewDataId }) => {
  const history = useHistory();
  const { viewLoading, viewError, viewContent } = useContext(
    CategoryManagerContext
  );

  const [objData, setData] = useState(propData);
  // console.log(objData);

  const optStatus = [
    { id: 'Active', title: 'Active' },
    // { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    // { id: 'Blocked', title: 'Blocked' },
  ];

  useEffect(() => {
    async function loadData() {
      viewContent();
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const handleSubmit = async data => {
    try {
      if (handleValidate()) {
        if (propNewData) {
          viewLoading();
          // if (propNewDataId !== '') {
          //   data.catId = propNewDataId;
          // }
          data.catId = '';
          console.log(`Data POST`, data);
          const resPostData = await api('POST', `/category`, data);
          console.log(resPostData);

          N.success('Successfully created');

          setTimeout(() => {
            history.push(`/category/${resPostData.data.resData.catId}`);
            window.location.reload();
          }, 1500);
        } else {
          viewLoading();
          console.log(`Data PUT`, data);
          const resPutData = await api(
            'PUT',
            `/category/${objData.catId}/info`,
            data
          );
          console.log(resPutData);

          N.success('Successfully updated');

          setTimeout(() => {
            // history.push('/');
            window.location.reload();
          }, 1500);
        }
      }

      function handleValidate() {
        let varControl = true;

        console.log(data);
        if (data._catStatus !== 'Active' && data._catStatus !== 'Disabled') {
          varControl = false;
          N.error('Status', 'Check parameters');
        } else {
          switch (data._catStatus) {
            case 'Active':
              data.catStatus = 0;
              break;
            case 'Disabled':
              data.catStatus = 2;
              break;
            default:
              varControl = false;
              N.error('Error Status', 'Check parameters');
              break;
          }
        }

        if (data.catName === '') {
          varControl = false;
          N.error('Name', 'Check parameters');
        }

        return varControl;
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 409) {
        N.error(error.response.data.resMessage);
        viewContent();
      } else {
        N.error('Error save');
        viewContent();
      }
    }
  };

  return (
    <>
      <S.Form onSubmit={handleSubmit} initialData={objData}>
        <S.DivInput propW="34%">
          <small>Status</small>
          <S.Select name="_catStatus" options={optStatus} disabled={false} />
        </S.DivInput>
        <S.DivInput propW="33%">
          <small>Date Created</small>
          <S.Input name="_catDateCreated" disabled />
        </S.DivInput>
        <S.DivInput propW="33%">
          <small>Date Updated</small>
          <S.Input name="_catDateUpdated" disabled />
        </S.DivInput>
        <S.DivInput propW="100%">
          <small>Name</small>
          <S.Input name="catName" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="100%">
          <small>Description</small>
          <S.Input name="catDescription" disabled={false} />
        </S.DivInput>
        <S.DivButton>
          <ButtonLoader propTitle="SAVE" propSpinner={false} />
        </S.DivButton>
      </S.Form>
      <NotificationContainer />
    </>
  );
};

Info.defaultProps = {
  propData: {},
  propNewData: false,
  propNewDataId: '',
};

Info.propTypes = {
  propData: PropTypes.object,
  propNewData: PropTypes.bool,
  propNewDataId: PropTypes.string,
};

export default Info;
