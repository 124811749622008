import React from 'react';

import { Link } from 'react-router-dom';

import imgLogo from '../../assets/images/logo_white.png';

import * as S from './styles';

const About = () => (
  <S.Container>
    <S.DivWrapper>
      <S.Img src={imgLogo} alt="Logo" />
      <S.H1>About</S.H1>
      <S.DivText>
        <S.H3>Version:</S.H3>
        <S.Text>"1.1.172"</S.Text>
      </S.DivText>
      <S.DivButton>
        <Link to="/">
          <S.Button>GO HOME</S.Button>
        </Link>
      </S.DivButton>
    </S.DivWrapper>
  </S.Container>
);

export default About;
